<template>
  <div class="divBox">
    <el-card class="box-card">
      <div>
        <el-tabs :tab-position="'left'" v-model="activeName">
          <el-tab-pane label="页面装修" name="index">
            <DecorateIndex v-if="activeName=='index'" />
          </el-tab-pane>
          <el-tab-pane label="个人中心" name="my">
            <DecorateMy v-if="activeName=='my'" />
          </el-tab-pane>
        </el-tabs>
      </div>
    </el-card>
  </div>
</template>
<script>
import {

} from "@/api/system/config";
import DecorateIndex from './components/decorateIndex.vue'
import DecorateMy from './components/decorateMy.vue'
export default {
  name: "agreements",
  components:{
    DecorateIndex,
    DecorateMy
  },
  data() {
    return {
      activeName:"index"
    };
  },
  created() {

  },
  methods: {
 
   
  },
};
</script>
<style scoped lang="scss">
.box-card /deep/.el-card__body {
  padding-left: 0px;
}
/deep/.el-tabs__item.is-active {
  color: var(--prev-color-primary);
  background-color: #ccc;
  background: #437efd1e;
  // opacity: 0.1;
}
/deep/.el-tabs--left .el-tabs__header.is-left {
  float: left;
  margin-bottom: 0;
  margin-right: 20px;
  height: 700px;
  width: 170px;
  font-size: 13px;
}
.gang {
  border: 2px solid var(--prev-color-primary);
  margin-right: 10px;
}
.btn {
  border-top: 1px solid #ccc;
  margin-top: 20px;
  .button {
    display: block;
    margin: 17px auto 0px;
  }
}
.content {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  img {
    max-width: 100%;
  }
  .ueditor {
    flex: 1;
    .font {
      font-size: 20px;
      font-weight: 600;
      color: #303133;
      margin-bottom: 30px;
    }
  }
}
.shu{
  width: 4px;
  height: 18px;
  background-color: #4073FA;
  margin-right: 5px;
}
</style>