<template>
  <div class="divBox">
    <!-- 预览 -->
    <div class="view">
      <ViewPhone :pageId="pageId" ref="viewPhone" />
    </div>
    <div class="table">
      <div class="head">
        <el-button
          icon="el-icon-plus"
          type="primary"
          size="mini"
          style="margin-right: 5px"
          @click="add"
          >添加</el-button
        >
        <!-- 注：初次进入该页面，可直接添加商城首页模板，也可先复制默认模板，再编辑默认模板保存后设为首页。 -->
      </div>
      <div style="margin-top: 15px">
        <el-table
          v-loading="listLoading"
          :data="tableData.data"
          size="small"
          highlight-current-row
        >
          <!-- <el-table-column prop="id" label="ID" min-width="180" /> -->
           <el-table-column
            type="index"
            :index="getTabelIndex"
            width="100"
            label="序号"
          />
          <el-table-column prop="name" label="模板名称" min-width="150" />
          <el-table-column prop="createTime" label="添加时间" min-width="150" />
          <el-table-column prop="updateTime" label="更新时间" min-width="150" />
          <el-table-column label="操作" min-width="350" fixed="right">
            <template slot-scope="scope">
              <el-button
                icon="el-icon-edit"
                type="text"
                size="small"
                @click="onEdit(scope.row.id)"
                >编辑</el-button
              >
              <el-button
                icon="el-icon-refresh"
                type="text"
                size="small"
                @click="del(scope.row.id)"
                >删除</el-button
              >
              <el-button
                icon="el-icon-view"
                type="text"
                size="small"
                @click="homeUpdateIndex(scope.row)"
                >{{scope.row.state==1?'取消设为首页':'设为首页'}}</el-button
              >
              <el-button
                icon="el-icon-view"
                type="text"
                size="small"
                @click="pageView(scope.row.id)"
                >切换预览</el-button
              >
              <el-button
                icon="el-icon-edit"
                type="text"
                size="small"
                @click="copy(scope.row.id)"
                >复制</el-button
              >
            </template>
          </el-table-column>
        </el-table>
        <div class="block">
          <el-pagination
            background
            :page-size="tableFrom.pageSize"
            :current-page="tableFrom.pageNum"
            layout="total, prev, pager, next, jumper"
            :total="tableData.total"
            @size-change="handleSizeChange"
            @current-change="pageChange"
          />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {
  getConfigList,
  homeUpdate,
  configDelete,
  configCopy,
} from "@/api/decorate/decorate";
import { getIndex } from "@/utils/other";
import ViewPhone from "./viewPhone.vue";
export default {
  name: "decorateIndex",
  components: {
    ViewPhone,
  },
  data() {
    return {
      pageId: "",
      activeName: "index",
      listLoading: false,
      tableData: {
        data: [],
        total: 0,
      },
      tableFrom: {
        pageNum: 1,
        pageSize: 20,
      },
    };
  },
  created() {
    this.getList();
  },
  mounted() {
    if (this.$store.state.pagesCustom.pageId) {
      this.$refs.viewPhone.getDetails(this.$store.state.pagesCustom.pageId);
    }
  },

  methods: {
    pageView(id) {
      this.pageId = id;
      this.$store.commit("setPageId", id);
      this.$refs.viewPhone.getDetails(id);
    },
    getList() {
      this.listLoading = true;
      getConfigList(this.tableFrom)
        .then((res) => {
          this.tableData.data = res.data.records;
          this.tableData.total = res.data.total;
          this.listLoading = false;
          if (
            !this.$store.state.pagesCustom.pageId &&
            this.tableData.data.length > 0
          ) {
            this.$refs.viewPhone.getDetails(this.tableData.data[0].id);
          }
        })
        .catch((res) => {
          this.listLoading = false;
        });
    },
    onEdit(id) {
      this.$router.push({
        path: "/pagesCustom/index",
        query: {
          id: id,
        },
      });
    },
    del(id) {
      this.$confirm("是否删除?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        configDelete(id).then((res) => {
          if (res.code === 200) {
            this.$message.success("删除成功");
            this.getList();
          } else {
            // this.$message.error(res.msg);
          }
        });
      });
    },
    homeUpdateIndex(row) {
      homeUpdate({
        id:row.id,
        state:row.state==1?2:1
      })
        .then((res) => { 
          if (res.code === 200) {
            this.$message.success(res.msg);
            this.getList();
          }
        })
        .catch((res) => {});
    },
    copy(id) {
      configCopy(id)
        .then((res) => {
          this.$message.success(res.msg);
          this.getList();
        })
        .catch((res) => {});
    },
    add() {
      this.$router.push({
        path: "/pagesCustom/index",
      });
    },
    pageChange(page) {
      this.tableFrom.pageNum = page;
      this.getList("");
    },
    handleSizeChange(val) {
      this.tableFrom.pageSize = val;
      this.getList(1);
    },
    getTabelIndex(index) {
      return getIndex(index, this.tableFrom.pageNum, this.tableFrom.pageSize);
    },
  },
};
</script>
<style scoped lang="scss">
.divBox {
  width: 100%;
  display: flex;
  min-height: 800px;
  align-items: center;
  justify-content: space-between;
}
.view {
  width: 390px;
  min-height: 800px;
  height: 100%;
}
.table {
  width: calc(100% - 400px);
  height: 100;
  min-height: 700px;
}
.head {
  display: flex;
  align-items: center;
  color: rgb(245, 100, 100);
  font-size: 13px;
}
</style>