<template>
  <div class="box">
    <div class="content">
		<div :style="{'background-image':`url(${backgroundImg})` }" class="top">
			<div class="nav_box">
				<div :style="{'height': statusBarHeight+'px'}"></div>
				<div class="home_nav">个人中心</div>
			</div>
			<div class="card">
				<div  class="info">
					<div class="user_info">
						<img mode="aspectFill" class="avatar"
							src='https://bn-qyg.oss-cn-hangzhou.aliyuncs.com/bn-qyg/1711868912905CFGxNoZ3B4kt00f3737814dc800863c73cebca7e58cc.png' />
						<div>
							<div class="nick">微信用户
								<!-- <text v-if="info.identity==1">普通用户</text> -->
								<text class="enterprise">企业会员</text>
							</div>
							<div class="phone">133********</div>
						</div>
						<!-- <div v-else @click="()=>{this.$global.jump('/pages/login/index')}" class="login_tip">请先登录
						</div> -->
					</div>
					<img  
						class="info_more" src="@/assets/images/info_more.png" mode="" />
				</div>
				<div class="number_list">
					<div class="number_item">
						<div class="number">999</div>
						<div class="number_name">余额(元)</div>
					</div>
					<!-- <div @click="()=>{this.$global.jump('/pages/profile/balance/index')}" class="number_item">
						<div class="number">99</div>
						<div class="number_name">我的余额</div>
					</div> -->
					<!-- <div @click="()=>{this.$global.jump('/pages/profile/record/index')}" class="number_item">
						<div class="number">{{statistics.browseCount}}</div>
						<div class="number_name">浏览记录</div>
					</div> -->
					<div class="number_item">
						<div class="number">999</div>
						<div class="number_name">积分账号(个)</div>
					</div>
				</div>
			</div>
		</div>
		<div class="menu_box">
			<!-- 订单相关 -->
			<div class="menu">
				<div class="menu_top">
					<div class="menu_name">我的订单</div>
					<div class="menu_more">全部订单
					</div>
				</div>
				<div class="menu_list">
					<div 
						v-for="(item,index) in orderType" :key="index" class="menu_item">
						<img class="menu_icon" :src="item.icon" />
						<div class="menu_text">{{item.name}}</div>
						<div v-if="item.count" class="count">{{item.count}}</div>
					</div>
				</div>
			</div>
			<div  class="contact_box">
				<div @click="callPhone('15010119225')" class="phone1"></div>
				<div @click="callPhone('4008809898')" class="phone2"></div>
				<img class="contact_image" :src="serviceImg" mode="aspectFill" />
				<!-- <img class="contact_image" src="https://bn-qyg.oss-cn-hangzhou.aliyuncs.com/bn-qyg/1711870675572xGvixHO9mJfT3d4b45a83f384bdd63b672dcd6de5116.png" mode="aspectFill" /> -->
			</div>
			<!-- 我的服务 -->
			<div class="menu">
				<div class="menu_top">
					<div class="menu_name">商城服务</div>
				</div>
				<div class="menu_list">
					<div  class="menu_item shop_item">
						<img class="menu_icon" src="@/assets/icon/menu_icon10.png" />
						<div class="menu_text">公告信息</div>
					</div>
					<div @click="()=>{this.$global.jump('/pages/home/messageList/index')}" class="menu_item shop_item">
						<img class="menu_icon" src="@/assets/icon/menu_icon2.png" />
						<div class="menu_text">客服消息</div>
					</div>
					<div @click="()=>{this.$global.jump('/pages/profile/about/index')}" class="menu_item shop_item">
						<img class="menu_icon" src="@/assets/icon/menu_icon3.png" />
						<div class="menu_text">关于商城</div>
					</div>
					<div @click="()=>{this.$global.jump('/pages/profile/address/list/index')}" class="menu_item shop_item">
						<img class="menu_icon" src="@/assets/icon/menu_icon4.png" />
						<div class="menu_text">我的地址</div>
					</div>
					<!-- <div @click="()=>{this.$global.jump('/pages/profile/invoice/list/index')}" class="menu_item">
						<img class="menu_icon" src="/static/imgs/profile/menu_icon5.png">
						</img>
						<div class="menu_text">发票管理</div>
					</div> -->
					<div @click="()=>{this.$global.jump('/pages/profile/feedback/index')}" class="menu_item shop_item">
						<img class="menu_icon" src="@/assets/icon/menu_icon5.png" />
						<div class="menu_text">意见反馈</div>
					</div>
					<div @click="()=>{this.$global.jump('/pages/home/agreement/index',{type:1})}" class="menu_item shop_item">
						<img class="menu_icon" src="@/assets/icon/menu_icon6.png" />
						<div class="menu_text">用户协议</div>
					</div>
					<div @click="()=>{this.$global.jump('/pages/profile/record/index')}" class="menu_item shop_item">
						<img class="menu_icon" src="@/assets/icon/menu_icon7.png"/>
						<div class="menu_text">浏览记录</div>
					</div>
					<div @click="()=>{this.$global.jump('/pages/profile/collect/index')}" class="menu_item shop_item">
						<img class="menu_icon" src="@/assets/icon/menu_icon8.png" />
						<div class="menu_text">我的收藏</div>
					</div>
				</div>
			</div>
		</div>
	</div>
  </div>
</template>
<script>
import order_state1 from "@/assets/icon/order_state1.png";
import order_state2 from "@/assets/icon/order_state2.png";
import order_state3 from "@/assets/icon/order_state3.png";
import order_state4 from "@/assets/icon/order_state4.png";
import order_state8 from "@/assets/icon/order_state8.png";
export default {
  name: "divPhoneMy",
  props: {
    backgroundImg: {
      type: String,
      default: "",
    },
	serviceImg: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      order_state1,
      order_state2,
      order_state3,
      order_state4,
      statusBarHeight: 50,
      orderType: [
        {
          name: "待支付",
          code: "0",
          path: "/pages/profile/order/list/index",
          icon: order_state1,
          count: 0,
        },
        {
          name: "待发货",
          code: "10",
          path: "/pages/profile/order/list/index",
          icon: order_state2,
          count: 0,
        },
        {
          name: "待收货",
          code: "20",
          path: "/pages/profile/order/list/index",
          icon: order_state3,
          count: 0,
        },
        {
          name: "待评价",
          code: "30",
          path: "/pages/profile/order/list/index",
          icon: order_state4,
          count: 0,
        },
        {
          name: "售后",
          path: "/pages/profile/afterSale/list/index",
          icon: order_state8,
          count: 0,
        },
      ],
    };
  },
  mounted() {
    // this.clear();
  },
  methods: {},
};
</script>
<style scoped lang="scss">
.box {
  height: 100%;
  box-shadow: 0 2px 10px #f0f0f0;
  width: 400px;
}
.content {

  background-color: #f0f0f0;
}

.home_nav {
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: PingFang SC, PingFang SC;
  font-weight: bold;
  font-size: 18px;
  color: #333333;
}

.top {
  width: 100%;
  height: 250px; 
  background-size: 100% 100%;
  background-repeat: no-repeat;
}

.card {
  padding: 0 10px;
}
.info {
  margin-top: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 10rpx;
  color: #ffffff;
  .user_info {
    display: flex;
    align-items: center;
    .avatar {
      width: 60px;
      height: 60px;
      border-radius: 50%;
      margin-right: 10px;
    }
    .nick {
      font-family: PingFang SC, PingFang SC;
      font-weight: bold;
      font-size: 16px;
      color: #333333;

      text {
        padding: 2.5px 6px;
        display: inline-block;
        background: #000000;

        margin-left: 8px;
        // color: #E93323;
        // color: rgba(255,255,255,0.6);
        border-radius: 4px;
        font-family: PingFang SC, PingFang SC;
        font-weight: 400;
        font-size: 12px;
        color: #ecc371;
      }
      .enterprise {
        background: #e93323;
        color: #ffffff;
      }
    }
    .phone {
      margin-top: 10px;
      font-family: PingFang SC, PingFang SC;
      font-weight: 400;
      font-size: 14px;
      color: #333333;
    }
  }
  .login_tip {
    color: #000;
    font-weight: bold;
  }
  .info_more {
    width: 20px;
    height: 20px;
  }
}
.number_list {
  margin-top: 15px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  text-align: center;
}
.number_item {
  width: 50%;
  .number {
    font-family: PingFang SC, PingFang SC;
    font-weight: 600;
    font-size: 20px;
    color: #000000;
  }
  .number_name {
    margin-top: 4px;
    font-family: PingFang SC, PingFang SC;
    font-weight: 400;
    font-size: 12px;
    color: #5c5c5c;
  }
}

.menu_box {
  position: relative;
  top: -15px;
  padding: 0px 15px;

  .menu {
	width:  100%;
   box-sizing: border-box;
    // height: 400rpx;
    border-radius: 5px;
    background-color: #ffffff;
    margin-bottom: 10px;
    padding: 10px;

    .menu_top {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .menu_name {
        font-weight: bold;
      }

      .menu_more {
        color: #666666;
        font-size: 24rpx;
      }
    }

    .menu_list {
      padding-top: 2.5px;
      display: flex;
      -webkit-box-lines: multiple;
      -moz-box-lines: multiple;
      -o-box-lines: multiple;
      flex-wrap: wrap;
    }

    .menu_item {
      position: relative;
      width: 20%;
      text-align: center;
      font-size: 12px;
      display: inline-block;
      margin-bottom: 5px;

      .menu_icon {
        display: block;
        width: 40px;
        height: 40px;
        margin: 0 auto;
      }

      .menu_text {
        font-size: 12px;
        color: #333333;
        // margin-top: 15rpx;
        font-weight: bold;
      }
      .count {
        position: absolute;
        top: 2.5px;
        right: 9px;
        width: 18px;
        height: 18px;
        display: flex;
        align-items: center;
        justify-content: center;
        border: 1px solid rgba(233, 51, 35, 0.5);
        border-radius: 50%;
        font-size: 10px;
        color: #e93323;
        background-color: rgba(255, 255, 255, 0.8);
      }
    }
    .shop_item {
      position: relative;
      width: 25%;
      button {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        opacity: 0;
      }
    }
  }
}
.contact_box {
  position: relative;
  padding: 5px;
  background-color: #ffffff;
  margin-bottom: 10px;
  border-radius: 5px;
  .phone1 {
    position: absolute;
    right: 17px;
    top: 70px;
    width: 150px;
    height: 40px;
  }
  .phone2 {
    position: absolute;
    right: 17px;
    top: 120px;
    width: 150px;
    height: 40px;
  }
}
.contact_image {
  width: 100%;
  height: 175px;
  position: relative;
  z-index: 111;
}
</style>