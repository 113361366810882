<template>
  <div class="divBox">
    <div class="view">
      <ViewPhoneMy :backgroundImg="backgroundImg" :serviceImg="serviceImg" />
    </div>
    <div class="table">
      <div style="margin-top: 15px">
        <div>配置页面</div>
        <div class="itemImgBox">
          <div class="tit">背景图片：</div>
          <div class="imgBox" v-if="backgroundImg">
            <i
              class="el-icon-error"
              @click="backgroundImg=''"
              style="font-size: 22px"
            ></i>
            <img :src="backgroundImg" />
          </div>
          <div class="imgBox" @click="showDio(1)" v-else>
            <i class="el-icon-camera-solid"></i>
            <!-- <img :src="item.img" @click="showDio(index)" /> -->
          </div>
        </div>        
      </div>

      <div style="margin-top: 15px">
        <div class="itemImgBox" v-if="serviceImg">
          <div class="tit">客服卡片：</div>
          <div class="imgBox">
            <i
              class="el-icon-error"
              @click="serviceImg=''"
              style="font-size: 22px"
            ></i>
            <img :src="serviceImg"/>
          </div>
        </div>

        <div class="itemImgBox" @click="showDio(2)" v-else>
          <div class="tit">客服卡片：</div>
          <div class="imgBox">
  
            <i class="el-icon-camera-solid"></i>
            <!-- <img :src="item.img" @click="showDio(index)" /> -->
          </div>
        </div>
      </div>

      <div class="btnBox">
        <el-button size="mini" @click="getView">重置</el-button>
        <el-button size="mini" type="primary" :loading='listLoading' @click="save" >保存</el-button>
      </div>
    </div>
    <el-dialog
      title="选择图片"
      :append-to-body="true"
      :visible.sync="show"
      width="80%"
    >
      <ChangeMaterial @changwImg="changwImg" />
    </el-dialog>
  </div>
</template>
<script>
import {
  indexConfigUpdate,
  indexConfigView
} from "@/api/decorate/decorate";
import ChangeMaterial from "@/view/decorate/page/components/changeMaterial";
import ViewPhoneMy from "./viewPhoneMy";
export default {
  name: "decorateMy",
  components: {
    ChangeMaterial,
    ViewPhoneMy
  },
  data() {
    return {
      show: false,
      backgroundImg:'',
      serviceImg:'',
      type:1,//1背景2客服
      clickIndex: null,
      listLoading:false,
    };
  },
  created() {
    this.getView()
  },
  methods: {
    getView() {
    
      indexConfigView(this.tableFrom)
        .then((res) => {
          this.backgroundImg = res.data.decorateBackgroundImage
          this.serviceImg = res.data.decorateServiceCard
        })
    },
    save(){
      this.listLoading = true;
      indexConfigUpdate({decorateBackgroundImage:this.backgroundImg,decorateServiceCard:this.serviceImg})
        .then((res) => {
          this.$message.success('保存成功')
          this.listLoading = false;
        })
        .catch((res) => {
          this.listLoading = false;
        });
    },
    showDio(val){
     this.type = val
     this.show = true
    },
    changwImg(val) {
       this.type==1?this.backgroundImg=val:this.serviceImg=val
      this.show = false;
    },
  },
};
</script>
<style scoped lang="scss">
.divBox {
  width: 100%;
  display: flex;
  min-height: 700px;
  align-items: center;
  justify-content: space-between;
}
.view {
  width: 480px;
  min-height: 700px;
  height: 100%;
}
.table {
  width: calc(100% - 380px);
  height: 100;
  min-height: 700px;
}
.head {
  display: flex;
  align-items: center;
  color: rgb(245, 100, 100);
  font-size: 13px;
}
.itemImgBox {
  width: 100%;
  height: 100px;
  display: flex;
  margin-top: 20px;
}
.tit {
  width: 70px;
  height: 100%;
  font-size: 14px;
}
.imgBox {
  width: 100px;
  height: 100px;
  position: relative;
  background-color: #f7f7f7;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  img{
    width: 100%;
    height: 100%;
  }
  .el-icon-error {
  position: absolute;
  top: -5px;
  right: -5px;
  color: rgb(153, 153, 153);
}
.el-icon-camera-solid{
  color: rgb(153, 153, 153);
  font-size: 30px
}
}
.btnBox{
  display: flex;
  padding-left: 70px;
  margin-top: 20px;
}
</style>