<template>
  <div class="boxContent">
  <div class="box">
    <section class="c">
      <div class="top-nav" @click="selectType(0)"></div>
      <div class="view-content">
        <template v-for="(item, index) in view">
          <template v-if="index > 0">
            <template
              v-if="
                item.type != 'search' &&
                item.type != 'tab' &&
                item.type != 'makeUpComponent' &&
                item.type != 'service' &&
                item.type != 'icon'
              "
            >
              <div
                :data-index="index"
                :key="item.num"
                :class="
                  actIndex == index
                    ? 'item itemStyles active'
                    : 'item itemStyles'
                "
                @click="selectType(index)"
              >
                <span class="rightTab" v-if="actIndex == index">
                  <span class="rightTabItem" @click="del(index)"
                    ><i class="el-icon-delete"></i
                  ></span>
                  <span class="rightTabItem" @click="copy(item, index)"
                    ><i class="el-icon-document-add"></i
                  ></span>
                  <span class="rightTabItem" @click="up(index)"
                    ><i class="el-icon-arrow-up"></i
                  ></span>
                  <span class="rightTabItem" @click="down(index)"
                    ><i class="el-icon-arrow-down"></i
                  ></span>
                </span>

                <!-- waiting -->
                <template v-if="item.status && item.status == 2">
                  <div class="wait">{{ item.type }}</div>
                </template>
                <template v-else>
                  <component
                    :index="index"
                    :is="typeList[item.type]['com']"
                    :data="item"
                    :num="item.num"
                  ></component>
                </template>
                <i @click="deleteItem($event, index)" class="el-icon-error"></i>
              </div>
            </template>

            <template v-else>
              <template v-if="item.type != 'service'&&item.type != 'icon'">
                <div
                  :data-index="index"
                  :key="item.num"
                  :class="
                    actIndex == index ? 'itemStyles active' : 'itemStyles'
                  "
                  @click="selectType(index)"
                >
                  <span class="rightTab" v-if="actIndex == index">
                    <span class="rightTabItem" @click="del(index)"
                      ><i class="el-icon-delete"></i
                    ></span>
                    <span class="rightTabItem" @click="copy(item, index)"
                      ><i class="el-icon-document-add"></i
                    ></span>
                    <span class="rightTabItem" @click="up(index)"
                      ><i class="el-icon-arrow-up"></i
                    ></span>
                    <span class="rightTabItem" @click="down(index)"
                      ><i class="el-icon-arrow-down"></i
                    ></span>
                  </span>

                  <!-- waiting -->
                  <template v-if="item.status && item.status == 2">
                    <div class="wait">{{ item.type }}</div>
                  </template>
                  <template v-else>
                    <component
                      :index="index"
                      :is="typeList[item.type]['com']"
                      :data="item"
                      :num="item.num"
                    ></component>
                  </template>
                  <i
                    @click="deleteItem($event, index)"
                    class="el-icon-error"
                  ></i>
                </div>
              </template>
            </template>
          </template>
        </template>
        <template v-for="(item, index) in view">
          <template v-if="item.type == 'service'">
            <div :data-index="index" :key="item.num" class="serviceVBox">
              <div
                :class="actIndex == index ? 'itemStyles active' : 'itemStyles'"
                style="background: none"
                @click="selectType(index)"
              >
                <template v-if="item.status && item.status == 2">
                  <div class="wait">{{ item.type }}</div>
                </template>
                <template v-else>
                  <component
                    :index="index"
                    :is="typeList[item.type]['com']"
                    :data="item"
                    :num="item.num"
                  ></component>
                </template>
                <i @click="deleteItem($event, index)" class="el-icon-error"></i>
              </div>
            </div>
          </template>
          <template v-if="item.type == 'icon'">
            <div :data-index="index" :key="item.num" class="iconVBox">
              <div
                :class="actIndex == index ? 'itemStyles active' : 'itemStyles'"
                @click="selectType(index)"
              >
                <template v-if="item.status && item.status == 2">
                  <div class="wait">{{ item.type }}</div>
                </template>
                <template v-else>
                  <component
                    :index="index"
                    :is="typeList[item.type]['com']"
                    :data="item"
                    :num="item.num"
                  ></component>
                </template>
                <i @click="deleteItem($event, index)" class="el-icon-error"></i>
              </div>
            </div>
          </template>
        </template>
        <div style="width:100;height:100px;"></div>
      </div>
    </section>
  </div>
</div>
</template>
<script>
import { getConfigView } from "@/api/decorate/decorate";
import Swiper from "../../../pagesCustom/components/View/Swiper";
import Activity from "../../../pagesCustom/components/View/Activity";
import Search from "../../../pagesCustom/components/View/Search";
import Tabs from "../../../pagesCustom/components/View/Tabs";
import MakeUpComponent from "../../../pagesCustom/components/View/MakeUpComponent";
import GoodsList from "../../../pagesCustom/components/View/GoodsList";
import Recommend from "../../../pagesCustom/components/View/Recommend";
import Navigation from "../../../pagesCustom/components/View/Navigation";
import News from "../../../pagesCustom/components/View/News";
import Icon from "../../../pagesCustom/components/View/Icon";
import Coupon from "../../../pagesCustom/components/View/Coupon";
import Presale from "../../../pagesCustom/components/View/Presale";
import Service from "../../../pagesCustom/components/View/Service";
import Title from "../../../pagesCustom/components/View/Title";
import Blank from "../../../pagesCustom/components/View/Blank";
import AdjuncLine from "../../../pagesCustom/components/View/AdjuncLine";
import RichText from "../../../pagesCustom/components/View/RichText";

import Product from "../../../pagesCustom/components/View/Product";
import Images from "../../../pagesCustom/components/View/Images";
export default {
  components: {
    Search,
    Recommend,
    Blank,
    Product,
    Images,
    Swiper,
    Tabs,
    MakeUpComponent,
    GoodsList,
    Navigation,
    News,
    Icon,
    Coupon,
    Presale,
    Activity,
    Service,
    Title,
    AdjuncLine,
    RichText,
  },
  name: "viewPhone",
  props: {
    pageId: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      actIndex: null,
      view: [
        {
          type: "info",
          title: "页面标题",
          num: 12312,
        },
      ],
      typeList: {
        swiper: {
          comName: "swiper",
          name: "轮播图",
          icon: "el-icon-picture",
          com: Swiper,
          type: 1,
          draggable: true,
        },
        search: {
          comName: "search",
          name: "搜索框",
          icon: "el-icon-s-goods",
          com: Search,
          type: 1,
          draggable: false,
        },
        tab: {
          comName: "tab",
          name: "选项标签",
          icon: "el-icon-picture",
          com: Tabs,
          type: 1,
          draggable: false,
        },
        makeUpComponent: {
          comName: "makeUpComponent",
          name: "组合组件",
          icon: "el-icon-picture",
          com: MakeUpComponent,
          type: 1,
          draggable: false,
        },
        goodsList: {
          comName: "goodsList",
          name: "商品列表",
          icon: "el-icon-picture",
          com: GoodsList,
          type: 1,
          draggable: true,
        },
        recommend: {
          comName: "recommend",
          name: "推荐组",
          icon: "el-icon-picture",
          com: Recommend,
          type: 1,
          draggable: true,
        },
        navigation: {
          comName: "navigation",
          name: "导航组",
          icon: "el-icon-picture",
          com: Navigation,
          type: 1,
          draggable: true,
        },
        news: {
          comName: "news",
          name: "新闻通知",
          icon: "el-icon-picture",
          com: News,
          type: 1,
          draggable: true,
        },
        images: {
          comName: "images",
          name: "图片魔方",
          icon: "el-icon-picture",
          com: Images,
          type: 1,
          draggable: true,
        },
        icon: {
          comName: "icon",
          name: "图标替换",
          icon: "el-icon-picture",
          com: Icon,
          type: 2,
          draggable: true,
        },
        // coupon: {
        //   comName: "coupon",
        //   name: "优惠券",
        //   icon: "el-icon-picture",
        //   com: Coupon,
        //   type: 3,
        //   draggable: true,
        // },
        presale: {
          comName: "presale",
          name: "预售",
          icon: "el-icon-picture",
          com: Presale,
          type: 3,
          draggable: true,
        },
        activity: {
          comName: "activity",
          name: "活动",
          icon: "el-icon-picture",
          com: Activity,
          type: 3,
          draggable: true,
        },
        service: {
          comName: "service",
          name: "在线客服",
          icon: "el-icon-picture",
          com: Service,
          type: 4,
          draggable: false,
        },
        title: {
          comName: "title",
          name: "标题",
          icon: "el-icon-picture",
          com: Title,
          type: 4,
          draggable: true,
        },
        blank: {
          comName: "blank",
          name: "辅助空白",
          icon: "el-icon-picture",
          com: Blank,
          type: 4,
          draggable: true,
        },
        adjuncLine: {
          comName: "adjuncLine",
          name: "辅助线",
          icon: "el-icon-picture",
          com: AdjuncLine,
          type: 4,
          draggable: true,
        },
        richText: {
          comName: "richText",
          name: "富文本",
          icon: "el-icon-picture",
          com: RichText,
          type: 4,
          draggable: true,
        },
      },
    };
  },
  mounted() {
    // this.clear();
  },
  methods: {
    getDetails(newValue) {
      getConfigView(newValue).then((res) => {
        let arr = [
          {
            type: "info",
            title: "页面标题",
          },
        ];
        this.view = arr.concat(JSON.parse(res.data.content));
        console.log("123", this.view);
        this.$nextTick(() => {
          this.selectType();
        });
      });
    },
    // 切换视图组件
    selectType() {
      this.$store.commit("setPage", this.view);
    },
    clear() {
      let arr = [
        {
          type: "info",
          title: "页面标题",
        },
      ];
      this.$store.commit("setPage", arr);
    },
  },
};
</script>
<style scoped lang="scss">
.boxContent{
  position: relative;
}
.box {
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;

  box-shadow: 0 2px 10px #d6d6d6;
  background: #f7f8f9;
}
.c { 
  width: 375px;
  height:auto;
  margin: 10px 0; 
  margin-left: 5px;
  .top-nav {
    position: absolute;
    top: 0;
    background: #fff;
    z-index: 999;
    transition: all 0.3s;
    & * {
      pointer-events: none;
    }
    &:hover {
      transform: scale(0.95);
      border-radius: 10px;
      overflow: hidden;
      box-shadow: 0 0 10px #afafaf;
    }
    .tit {
      position: absolute;
      left: 50%;
      bottom: 10px;
      transform: translateX(-50%);
    }
    img {
      max-width: 100%;
      image-rendering: -moz-crisp-edges;
      image-rendering: -o-crisp-edges;
      image-rendering: -webkit-optimize-contrast;
      image-rendering: crisp-edges;
      -ms-interpolation-mode: nearest-neighbor;
    }
  }
  .view-content {
    width: 375px;
    height: 750px;
    // background: #f5f5f5;
    // overflow-y: auto;
    // overflow-x: hidden;
    padding-top: 72px;
    padding-top: 0px;
    padding-bottom: 60px;
    
    box-sizing: border-box;
    &::-webkit-scrollbar {
      width: 6px;
    }
    &::-webkit-scrollbar-thumb {
      background: #dbdbdb;
    }
    &::-webkit-scrollbar-track {
      background: #f6f6f6;
    }

    .itemStyles {
      height: max-content;
      position: relative;
      width: 100%;
      .rightTab {
        z-index: 100;
        display: block;
        position: absolute;
        right: -45px;
        top: 0;
        width: 36px;
        border-radius: 4px;
        background: #4073fa;
        cursor: pointer;
        color: #fff;
        font-weight: 700;
        text-align: center;
        padding: 4px 0;
        .rightTabItem {
          display: block;
          width: 100%;
          height: 27px;
          z-index: 10000;
        }
      }
      div {
        pointer-events: none;
      }
      .wait {
        display: block !important;
        height: 40px;
        text-align: center;
        line-height: 40px;
        border: 1px dashed #4073fa;
        color: #4073fa;
        background-color: #edf4fb;
      }
      .el-icon-error {
        position: absolute;
        right: -10px;
        top: -6px;
        color: red;
        font-size: 25px;
        cursor: pointer;
        display: none;
        z-index: 9999;
      }
    }
  }
}
.serviceVBox {
  position: absolute !important;
  bottom: 100px;
  right: 20px;
  width: 54px !important;
  height: 54px !important;
  background: none !important;
  z-index: 100000;
}
.iconVBox {
  position: absolute !important;
  width: 373px;
  height: 80px;
  bottom: 0;
  left: 0;
  background-color: #fff !important;
  z-index: 1000;
  box-shadow: 0 2px 10px #d6d6d6;
}
</style>